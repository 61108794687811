import { Operation } from 'types/Operation';

export enum DossierStatus {
  EtudeEnCours = 'Etude en cours',
  OffreEmise = 'Offre émise',
  ChantierEnCours = 'Chantier en cours',
  DocumentsEnAttente = 'Documents en attente',
  Recu = 'Dossier reçu',
  EnAttenteDeControle = 'En attente de passage bureau de contrôle',
  Incomplet = 'Dossier incomplet',
  Valide = 'Dossier validé',
  PrimeProPayee = 'Prime Pro payée',
  PrimeBeneficiairePayee = 'Part Bénéficiaire payée',
  PrimeGlobalePayee = 'Prime Globale Payée',
  Refuse = 'Refusé',
  Abandon = 'Abandon',
  DemandeInformations = "Demande d'informations",
  EnCoursDeValidation = 'Dossier en cours de validation',
}

export enum DossierSortFields {
  id = 'id',
  raisonSociale = 'beneficiaire_raisonSociale',
  nomSite = 'site_nom',
  adresse = 'site_adresse',
  date = 'dateModification',
  dateFinTravaux = 'chantier_dateFinTravaux',
  referenceChantier = 'chantier_reference',
  montantPrimes = 'chantier_montant',
  statut = 'statut',
  referentCommercial = 'referentCommercial',
}

export const FilterDossierStatus = [
  DossierStatus.EtudeEnCours,
  DossierStatus.EnCoursDeValidation,
  DossierStatus.Incomplet,
  DossierStatus.OffreEmise,
  DossierStatus.EnAttenteDeControle,
  DossierStatus.Refuse,
  DossierStatus.ChantierEnCours,
  DossierStatus.Valide,
  DossierStatus.Abandon,
  DossierStatus.DocumentsEnAttente,
];

export const DossierStatusKeys = Object.fromEntries(
  Object.entries(DossierStatus).map(([key, value]) => [value, key]),
);

export interface Base64File {
  name: string;
  content: string;
  type?: string;
}

export interface DossierHistoryItem {
  date: string;
  value: DossierStatus;
}

export class Entreprise {
  readonly raisonSociale?: string;
  readonly siret?: string;
  readonly adresse?: string;
  readonly codePostal?: string;
  readonly ville?: string;
  readonly email?: string;
  readonly telephone?: string;
}

export interface Chantier {
  reference?: string;
  montant?: string;
  entreprise?: Entreprise;
  natureTravaux?: string;
  dateDebutTravaux?: string;
  dateFinTravaux?: string;
  dateEngagement?: string;
}

export interface Site {
  nom?: string;
  usage?: string;
  adresse?: string;
  codePostal?: string;
  ville?: string;
}

export interface Batiment {
  ancien?: boolean;
  ancienneEnergieChauffage?: string;
  nouvelleEnergieChauffage?: string;
  surfaceChauffee?: number;
  surfaceChauffeeRehabilitee?: number;
  surfaceVentileeRehabilitee?: number;
}

export interface Personne {
  firstname?: string;
  lastname?: string;
  email?: string;
}
export interface Beneficiaire extends Personne {
  raisonSociale?: string;
  siren?: string;
  adresse?: string;
  codePostal?: string;
  ville?: string;
  civilite?: string;
  telephone?: string;
}

export interface DossierDetails {
  id: string;
  statut: DossierStatus;
  dateModification?: Date;
  chantier?: Chantier;
  site?: Site;
  beneficiaire?: Beneficiaire;
  referentCommercial?: Personne;
  proprietaire?: Personne;
  history?: DossierHistoryItem[];
  operations?: Operation[];
}

export interface Dossiers {
  records: DossierDetails[];
  totalSize: number;
}

export interface DossierLead {
  typeOperation?: string;
  description?: string;
  beneficiaire: Beneficiaire;
  referentCommercial: Personne;
  site: Site;
  batiment: Batiment;
  chantier: Chantier;
  modeleIncitationFinanciere?: string;
  optin?: boolean;
}

export interface CreateDossierLead {
  dossier: DossierLead;
  files: Base64File[];
}
